import React from 'react';
import PortableText from 'react-portable-text';
import { GatsbyImage } from 'gatsby-plugin-image';

import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Container,
  Button,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { WaveUpSVG } from '../../WaveSVGs/WaveUpSVG';

const useStyles = makeStyles((theme) => ({
  ucLogo: {
    maxWidth: '800px',
  },
  text: {
    color: theme.white,
    lineHeight: '.7',
    fontWeight: 400,
    marginBottom: '1rem',
    textAlign: 'center',
  },
  header: {
    lineHeight: 1.1,
    fontWeight: 600,
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.2rem',
    },
  },
  headerText: {
    color: theme.workwaveBlue,
    textAlign: 'center',
    fontSize: '1.5rem',
    paddingTop: '2rem',
    fontWeight: 500,
    lineHeight: 1.2,
  },
  imgCont: {
    padding: '6rem 0',
    maxWidth: '100vw',
    overflowX: 'hidden',
    background:
      'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
    [theme.breakpoints.down('lg')]: {
      padding: '4rem 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '3rem 0 6rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 0 2rem 0',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0 0 0',
      // paddingBottom: '4rem',
      backgroundPosition: 'right',
    },
  },
  imgContUC: {
    padding: '6rem 0 12rem 0',
    maxWidth: '100vw',
    overflowX: 'hidden',
    background:
      'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
    [theme.breakpoints.down('lg')]: {
      padding: '8rem 0',
    },
    [theme.breakpoints.down('md')]: {
      padding: '3rem 0 6rem 0',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '3rem 0 2rem 0',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '2rem 0 3rem 0',
      // paddingBottom: '4rem',
      backgroundPosition: 'right',
    },
  },
  imgRight: {
    marginLeft: '-10rem',
    [theme.breakpoints.down('lg')]: {
      marginLeft: '-9rem',
      height: '560px',
    },
    // [theme.breakpoints.down('md')]: {
    // 	marginLeft: '-4rem',
    // 	height: '380px',
    // },
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-8rem',
      height: '300px',
    },
    [theme.breakpoints.down('xs')]: {
      marginLeft: '0',
      height: '260px',
    },
  },
  star: {
    marginRight: '.5rem',
    color: '#FFCA43',
  },
  wave: {
    marginTop: '-10rem',
    [theme.breakpoints.down('lg')]: {
      marginTop: '-9rem',
    },
    // [theme.breakpoints.down('md')]: {
    // 	marginTop: '-7rem',
    // },
    // [theme.breakpoints.down('sm')]: {
    // 	marginTop: '-7rem',
    // },
    [theme.breakpoints.down('xs')]: {
      marginTop: '-12rem',
    },
  },
  button: {
    // background: 'white',
    // color: '#002D5C',
    marginTop: '2rem',
    padding: '12px 40px',
    opacity: 1,
    // fontSize: '1.1rem',
    fontWeight: 500,
    zIndex: 1000,
    transition: 'all 0.35s ease !important',
    '&:hover': {
      opacity: '0.85 !important',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '1rem 0 2rem 0',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '1rem 0 3rem 0',
    },
  },
  pill: {
    backgroundColor: '#0f94a3',
    color: theme.white,
    fontWeight: 500,
    padding: '15px 20px',
    marginTop: '3rem',
    borderRadius: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '600px',
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
    },
    [theme.breakpoints.down(484)]: {
      fontSize: '1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
      maxWidth: '355px',
      fontSize: '.9rem',
    },
  },
  bullet: {
    listStyle: 'none',
    '&::before': {
      content: `'\\2022'`,
      color: theme.white,
      width: '1em',
      display: 'inline-block',
      marginLeft: '-1em',
    },
  },
  ctaText: {
    padding: '1.5rem 0 8rem',
    color: '#fff',
    [theme.breakpoints.down('lg')]: {
      padding: '1.5rem 0 6rem',
    },
    [theme.breakpoints.down('md')]: {
      padding: '1.5rem 0 3rem',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '1.5rem 0 4rem',
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '1.5rem',
      padding: '1.5rem 0 1rem',
    },
  },
}));

export const EventsHero = ({ hero, uc }) => {
  const classes = useStyles();
  const xs = useMediaQuery('(max-width: 481px)');
  return (
    <>
      <div
        className={uc ? classes.imgContUC : classes.imgCont}
        style={{
          backgroundImage: `url(${hero?.backgroundImage?.asset?.gatsbyImageData.images.fallback.src})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        <Container fixed>
          <Grid container direction='row' justifyContent='center'>
            <Grid
              container
              item
              direction='row'
              justifyContent='center'
              alignItems='center'
              xs={12}
            >
              {uc ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <GatsbyImage
                    className={classes.ucLogo}
                    image={hero.image?.asset?.gatsbyImageData}
                  />
                  <Typography
                    variant='h4'
                    className={classes.pill}
                    style={{ color: 'white' }}
                  >
                    {hero.pillUCDate}
                    {/* css creates bullet with the li tag */}
                    <ul
                      style={{
                        marginBottom: 0,
                        marginBlockStart: 0,
                        paddingInlineStart: xs ? '20px' : '40px',
                      }}
                    >
                      <li className={classes.bullet}></li>
                    </ul>
                    {hero.pillUCLocation}
                  </Typography>
                </div>
              ) : (
                <Typography
                  variant='h1'
                  style={{ color: 'white', padding: '6rem 0 10rem 0' }}
                >
                  UPCOMING EVENTS
                </Typography>
              )}
            </Grid>
            {hero._rawHeroText && (
              <Grid
                container
                item
                direction='row'
                justifyContent='center'
                alignItems='center'
                xs={12}
              >
                <PortableText
                  content={hero._rawHeroText}
                  serializers={{
                    strong: ({ children }) => <strong>{children}</strong>,
                    normal: ({ children }) => (
                      <Typography className={classes.headerText}>
                        {children}
                      </Typography>
                    ),
                  }}
                />
              </Grid>
            )}
            {hero.ctaLink ? (
              <a
                href={hero.ctaLink}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <Button
                  variant='contained'
                  size='large'
                  color='secondary'
                  className={classes.button}
                >
                  {hero.ctaText}
                </Button>
              </a>
            ) : hero.ctaText ? (
              <Grid
                container
                item
                direction='row'
                justifyContent='center'
                alignItems='center'
                xs={12}
              >
                <Typography
                  variant='h3'
                  color='primary'
                  className={classes.ctaText}
                >
                  {hero.ctaText}
                </Typography>
              </Grid>
            ) : null}
          </Grid>
        </Container>
      </div>
      {!uc && (
        <div>
          <WaveUpSVG height='213' width='100%' fill='#FFF' />
        </div>
      )}
    </>
  );
};
