import React from 'react';
import { Button, Card, makeStyles, Typography, Grid } from '@material-ui/core';
import { GatsbyImage } from 'gatsby-plugin-image';
import PortableText from 'react-portable-text';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 0,
    width: '100%',
    height: '200px',
    marginBottom: '2rem',
    border: '2px solid #E5EAF4',
    borderRadius: '22px',
    [theme.breakpoints.down('md')]: { height: 'auto', paddingBottom: '2rem' },
  },
  image: {
    height: '100%',
    borderRadius: '20px 0 0 20px',
    [theme.breakpoints.down('md')]: {
      borderRadius: '20px 20px 0 0',
      height: '200px',
    },
  },
  content: {
    paddingLeft: '4rem',
    [theme.breakpoints.down('md')]: {
      padding: '2rem 0',
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  tags: {
    background: '#055291',
    marginBottom: '5px',
    borderRadius: '30px',
    color: 'white',
    fontWeight: 500,
    padding: '5px 15px',
    textTransform: 'uppercase',
    fontSize: '12px',
  },
}));

export const EventsCard = ({ event }) => {
  const { title, image, content, ctaLink, ctaText, description, tags } = event;
  const classes = useStyles();

  const dateMatch = content.match(/([A-Za-z]+) (\d+)(?:-\d+)?, (\d{4})/);

  if (dateMatch) {
    const [, month, day, year] = dateMatch;

    // Convert to a Date object (using the first day of the range)
    const eventDate = new Date(`${month} ${day}, ${year}`);

    // Get today's date without time
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Check if the event date is before today
    if (eventDate < today) {
      return null;
    }
  }

  return (
    <Grid className={classes.card} container>
      <Grid item style={{ maxHeight: '100%' }} lg={3}>
        <GatsbyImage
          image={image?.asset.gatsbyImageData}
          alt={title}
          className={classes.image}
        />
      </Grid>

      <Grid
        item
        container
        direction='column'
        justifyContent='center'
        alignItems='flex-start'
        lg={6}
        className={classes.content}
      >
        {tags.length ? (
          <>
            {tags.map((tag, index) => (
              <Typography variant='body2' className={classes.tags} key={index}>
                {tag}
              </Typography>
            ))}
          </>
        ) : null}
        <Typography variant='h4' color='primary' style={{ fontWeight: 700 }}>
          {title}
        </Typography>
        <Typography variant='body1'>{content}</Typography>
        {description ? (
          <Typography variant='body1' style={{ paddingTop: '1rem' }}>
            {description}
          </Typography>
        ) : null}
      </Grid>
      <Grid item container alignItems='center' justifyContent='center' lg={3}>
        {ctaLink ? (
          <Button
            variant='contained'
            color='primary'
            component='a'
            href={ctaLink}
            size='large'
            target='_blank'
          >
            {ctaText}
          </Button>
        ) : null}
      </Grid>
    </Grid>
  );
};
