import React, { useState } from 'react';

import { Button, Grid, ButtonGroup, useMediaQuery } from '@material-ui/core';

import { EventsCard } from './EventsCard';

export const EventsBody = ({ events, eventLabels }) => {
  const [selectedEvents, setSelectedEvents] = useState(0);

  const handleClick = (index) => {
    setSelectedEvents(index);
  };

  const filteredEvents =
    selectedEvents === 0
      ? events.filter((event) => event.tradeshow)
      : events.filter((event) => !event.tradeshow);

  const md = useMediaQuery('(max-width: 960px)');

  return (
    <>
      <Grid
        container
        justifyContent='center'
        style={{ padding: '2rem 0 4rem 0' }}
      >
        <ButtonGroup>
          {eventLabels.map((label, index) => (
            <Button
              key={index}
              size={md ? 'small' : 'large'}
              onClick={() => handleClick(index)}
              variant={selectedEvents === index ? 'contained' : 'outlined'}
              color={selectedEvents === index ? 'primary' : 'default'}
            >
              {label}
            </Button>
          ))}
        </ButtonGroup>
      </Grid>
      <Grid>
        {filteredEvents.map((event, index) => (
          <EventsCard key={index} event={event} />
        ))}
      </Grid>
    </>
  );
};
